export default function Contacts() {
  return (
    <section id="contact" className="section contact">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12">
            <div className="block">
              <div className="heading wow fadeInUp">
                <h2>Susisiekime!</h2>
                <p>
                  Norime išgirsti jūsų komentarus, sėkmės istorijas ir pastabas.
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-xs-12 col-sm-12 col-md-6 offset-md-3 wow fadeInUp"
            data-wow-delay="0.3s"
          >
            <div className="h3 text-center">
              Mūsų paštas: <a href="mailto:info@pitonas.lt">info@pitonas.lt</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
