import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faForwardStep,
  faMagnifyingGlassMinus,
  faMagnifyingGlassPlus,
  faPlay,
  faStop,
} from "@fortawesome/free-solid-svg-icons";
import Editor from "@monaco-editor/react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { parseBase64 } from "helpers/utils";
import "./Ide.scss";

declare global {
  interface Window {
    Sk: any;
  }
}

// declare globally
let STOP_EXEC = false;

export default function Ide() {
  const params = useParams();
  const _code = parseBase64(params.id);
  const [code, setCode] = useState(_code);
  const [response, setResponse] = useState("");
  const [isRunning, setIsRunning] = useState(false);
  const [fontSize, setFontSize] = useState(14);

  const Sk = window.Sk || {};
  const result: string[] = [];

  function outf(text: string) {
    result.push(text);
    setResponse(result.join(""));
  }
  function builtinRead(x: any) {
    if (
      Sk.builtinFiles === undefined ||
      Sk.builtinFiles["files"][x] === undefined
    )
      // eslint-disable-next-line no-throw-literal
      throw "File not found: '" + x + "'";
    return Sk.builtinFiles["files"][x];
  }

  function runit() {
    STOP_EXEC = false;
    setIsRunning(true);
    result.length = 0;

    Sk.pre = "output";
    Sk.configure({
      output: outf,
      read: builtinRead,
      killableWhile: true,
      killableFor: true,
    });
    Sk.__future__.unicode_literals = true;
    (Sk.TurtleGraphics || (Sk.TurtleGraphics = {})).target = "turtleCanvas";

    Sk.misceval
      .asyncToPromise(
        () => Sk.importMainWithBody("<stdin>", false, code, true),
        {
          "*": () => {
            // eslint-disable-next-line no-throw-literal
            if (STOP_EXEC) throw "Execution interrupted";
          },
        }
      )
      .catch((err: any) => {
        outf("\n" + err.toString());
      })
      .finally(() => {
        setIsRunning(false);
      });
  }
  function stopit() {
    STOP_EXEC = true;
  }

  function handleEditorChange(value: string | undefined) {
    setCode(value ?? "");
  }

  function onZoomIn() {
    if (fontSize < 30) setFontSize(fontSize + 1);
  }
  function onZoomOut() {
    if (fontSize > 10) setFontSize(fontSize - 1);
  }

  return (
    <main id="main" className="d-flex flex-column">
      <nav className="navbar navbar-dark bg-dark">
        <div className="container-fluid">
          <a className="navbar-brand" href="/ide">
            Online Pitono redaktorius
          </a>
          <form style={{ marginRight: "auto", marginLeft: "50px" }}>
            <button
              className="btn btn-sm btn-outline-success me-2 px-3"
              type="button"
              disabled={isRunning}
              onClick={() => runit()}
            >
              <FontAwesomeIcon icon={faPlay} />
            </button>
            <button
              className="btn btn-sm btn-outline-danger me-2 px-3"
              type="button"
              onClick={stopit}
            >
              <FontAwesomeIcon icon={faStop} />
            </button>
            <button
              className="btn btn-sm btn-outline-secondary px-3 disabled"
              type="button"
            >
              <FontAwesomeIcon icon={faForwardStep} />
            </button>
            <span className="m-2 border h-100 w-1"></span>
            <button
              className="btn btn-sm btn-outline-secondary me-2 px-3"
              type="button"
              onClick={onZoomIn}
            >
              <FontAwesomeIcon icon={faMagnifyingGlassPlus} />
            </button>
            <button
              className="btn btn-sm btn-outline-secondary px-3"
              type="button"
              onClick={onZoomOut}
            >
              <FontAwesomeIcon icon={faMagnifyingGlassMinus} />
            </button>
          </form>
        </div>
      </nav>
      <div className="row m-0 flex-grow-1">
        <div className="col-md-6 p-1">
          <div
            id="ideContainer"
            className="w-100 h-100 p border overflow-hidden rounded-3 shadow"
          >
            <Editor
              language="python"
              height={"100%"}
              theme="vs-dark"
              value={code}
              options={{ padding: { top: 10, bottom: 10 }, fontSize: fontSize }}
              onChange={handleEditorChange}
            ></Editor>
          </div>
        </div>
        <div
          id="gamePanel"
          className="col-md-6 p-0 border"
          style={{ backgroundColor: "#f3f3f3" }}
        >
          <div className="container-fluid">
            <div className="row">
              <div id="turtleCanvas" />
            </div>
            <div className="row p-2">
              <pre className="col-12" id="pythonLog" style={{ fontSize }}>
                {response}
              </pre>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
