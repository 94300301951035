import { Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./App.scss";
import { Footer } from "./components";
import { Home, Ide } from "./pages";
import Consent from "components/Consent/Consent";

function App() {
  return (
    <>
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/ide" element={<Ide />} />
            <Route path="/ide/:id" element={<Ide />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <Consent />
        </Suspense>
      </Router>
      <Footer />
    </>
  );
}

export default App;
