import { useEffect, useState } from "react";
import CookieConsent from "react-cookie-consent";
import { gtag, install } from "ga-gtag";
import "./Consent.scss";

const tag = "G-HBTRCZL963";
const consentKey = `ga4-consent-${tag}`;
const cookieName = "pitonas-cookie-consent";

interface IConsentMode {
    necessary: boolean;
    marketing: boolean;
    analytics: boolean;
    preferences: boolean;
}

const Consent = () => {
    const [analytics, setAnalytics] = useState(true);
    const [preferences, setPreferences] = useState(true);
    const [marketing, setMarketing] = useState(true);
    
    const onChangeAnalytics = () => setAnalytics(!analytics);
    const onChangePreferences = () => setPreferences(!preferences);
    const onChangeMarketing = () => setMarketing(!marketing);
    
    const getConsent = (consent: IConsentMode) => ({
        "functionality_storage": consent.necessary ? "granted" : "denied",
        "security_storage": consent.necessary ? "granted" : "denied",
        "ad_storage": consent.marketing ? "granted" : "denied",
        "analytics_storage": consent.analytics ? "granted" : "denied",
        "personalization": consent.preferences ? "granted" : "denied"
    });

    const setConsent = (consent: IConsentMode) => {
        const consentMode = getConsent(consent);
        gtag("consent", "update", consentMode);
        localStorage.setItem(consentKey, JSON.stringify(consentMode));
    };

    const onAccept = () => {
        setConsent({necessary: true, marketing, analytics, preferences});
    };
  
    useEffect(() => {
        const storedConsent = localStorage.getItem(consentKey);
        const consentMode = storedConsent ? JSON.parse(storedConsent) : getConsent({} as IConsentMode);

        install(tag, consentMode);
    }, []);

    return (
        <CookieConsent
            location="bottom"
            overlay={true}
            flipButtons={true}
            buttonText="Sutinku su pažymėtais"
            declineButtonText="Nesutinku"
            cookieName={cookieName}
            expires={30}
            disableStyles={true}
            disableButtonStyles={true}
            enableDeclineButton={true}
            containerClasses="cookies-consent-container"
            contentClasses="cookies-consent-content"
            overlayClasses="cookies-overlay"
            buttonWrapperClasses="cookies-buttons-wrapper"
            buttonClasses="cookies-button primary"
            declineButtonClasses="cookies-button"
            onAccept={onAccept}
        >
            <div>
                <h2>Šioje svetainėje yra naudojami slapukai</h2>
                <div>Norėdami užtikrinti jums geriausią naršymo patirtį, šioje svetainėje naudojame slapukus. Paspaudę mygtuką „Sutinku su pažymėtais“, patvirtinsite pažymėtų slapukų naudojimą.</div>
                <div className="cookie-options">
                    <div>
                        <input type="checkbox" checked={true} id="necessary" disabled/>
                        <label htmlFor="necessary">Būtini</label>
                    </div>
                    <div>
                        <input type="checkbox" checked={analytics} onChange={onChangeAnalytics} id="analytics"/>
                        <label htmlFor="analytics">Statistika</label>
                    </div>
                    <div>
                        <input type="checkbox" checked={preferences} onChange={onChangePreferences} id="preferences"/>
                        <label htmlFor="preferences">Nuostatos</label>
                    </div>
                    <div>
                        <input type="checkbox" checked={marketing} onChange={onChangeMarketing} id="marketing"/>
                        <label htmlFor="marketing">Rinkodara</label>
                    </div>
                </div>
            </div>
        </CookieConsent>
    );
};

export default Consent;
