import { Fade, Slide } from "react-awesome-reveal";
import "./Quote.scss";

export default function Quote() {
  return (
    <section className="call-to-action section">
      <div className="container">
        <Fade className="row">
          <Slide className="col-auto text-center m-auto" triggerOnce>
            <figure className="block">
              <h2>Programavimas - tai ateities kalba</h2>
              <blockquote className="blockquote">
                Pitonas - pati galingiausia kalba, kurią vis dar gali skaityti.
              </blockquote>
              <figcaption className="blockquote-footer">
                Paul F. Dubois, <cite>Ph.D</cite>
              </figcaption>
            </figure>
          </Slide>
        </Fade>
      </div>
    </section>
  );
}
