import React from "react";

export default function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="block">
              <p>
                Copyright © <a href="http://www.pitonas.lt">Pitonas.LT</a>| All
                right reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
