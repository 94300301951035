import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Fade, Slide } from "react-awesome-reveal";
import { Carousel } from "react-responsive-carousel";

export default function About() {
  return (
    <section id="about" className="section about bg-gray">
      <div className="container">
        <div className="row">
          <Fade className="col-md-7 col-sm-12 wow fadeInLeft mb-4">
            <Slide triggerOnce>
              <div className="content">
                <div className="sub-heading">
                  <h2>
                    Python programavimo kalba - viena pačių populiariausių,
                    naudingiausių ir patogiausių programavimo kalbų pasaulyje.
                  </h2>
                </div>
                <p>
                  Su Pitonu programuoja duomenų mokslininkai, web
                  programuotojai, dirbtinio intelekto profesionalai ir robotų
                  konstuktoriai.
                </p>
                <p>
                  Tai <b>#1</b> programavimo mokymosi kalba pasaulio
                  universitetuose, ir tarp <b>Top 5</b> kalbų profesionalų
                  programuotojų inventoriuje.
                </p>
              </div>
            </Slide>
          </Fade>
          <div
            className="col-md-5 col-sm-12 wow fadeInLeft"
            data-wow-delay="0.3s"
          >
            <Carousel
              autoPlay
              infiniteLoop
              showArrows={false}
              showStatus={false}
              showThumbs={false}
              showIndicators={false}
            >
              <div>
                <img src="about/1.jpg" alt="laptopas" />
              </div>
              <div>
                <img src="about/2.jpg" alt="kodas ir moteris" />
              </div>
              <div>
                <img src="about/3.jpg" alt="robotas" />
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
}
