import About from "./components/About";
import Contacts from "./components/Contacts";
import Navbar from "./components/Navbar";
import Quote from "./components/Quote";
import Services from "./components/Services";
import Welcome from "./components/Welcome";

export default function Home() {
  return (
    <>
      <div className="container">
        <Navbar />
      </div>
      <Welcome />
      <About />
      <Services />
      <Quote />
      <Contacts />
    </>
  );
}
