import { Fade, Slide } from "react-awesome-reveal";

export default function Services() {
  return (
    <section id="service" className="service section">
      <div className="container">
        <div className="row">
          <Fade className="heading">
            <Slide direction="up" triggerOnce>
              <h2>Nuo ko pradėti mokytis Python</h2>
              <p>Atėjai ten, kur reikia! Nepamiršk užsižymėti šį puslapį</p>
            </Slide>
          </Fade>
          <Fade className="block col-sm-6 col-md-4" triggerOnce>
            <Slide triggerOnce>
              <a href="https://wiki.pitonas.lt/vadovelis">
                <i className="tf-strategy" />
                <h3>Python vadovėlis</h3>
                <p>
                  Išverstas į lietuvių kalbą online vadovėlis, recenzuotas
                  Lietuvos akademikų.
                </p>
              </a>
            </Slide>
          </Fade>
          <Fade className="block col-sm-6 col-md-4" triggerOnce delay={100}>
            <Slide triggerOnce delay={100}>
              <a href="https://wiki.pitonas.lt/pamokos">
                <i className="tf-circle-compass" />
                <h3>Aktyvios pamokos</h3>
                <p>
                  doc Eimučio Karčiausko sukurtas pamokų ciklas KTU studentams
                  (tinka ir jaunesniems)
                </p>
              </a>
            </Slide>
          </Fade>
          <Fade className="block col-sm-6 col-md-4" triggerOnce delay={300}>
            <Slide triggerOnce delay={300}>
              <a href="ide.html">
                <i className="tf-circle-compass" />
                <h3>Programavimo aplinka</h3>
                <p>
                  Online kodo redaktorius su integruotu online Pythonu. Rašyk
                  kodą tiesiai naršyklėje.
                </p>
              </a>
            </Slide>
          </Fade>
          <Fade className="block col-sm-6 col-md-4" triggerOnce delay={600}>
            <Slide triggerOnce delay={600}>
              <a href="https://wiki.pitonas.lt/egzaminai">
                <i className="tf-anchor2" />
                <h3>IT Brandos Egzaminai</h3>
                <p>
                  Patarimai moksleiviams, norintiems laikyti IT valstybinį
                  brandos egzaminą (VBE)
                </p>
              </a>
            </Slide>
          </Fade>
          <Fade className="block col-sm-6 col-md-4" triggerOnce delay={900}>
            <Slide triggerOnce delay={900}>
              <a href="https://wiki.pitonas.lt/uzduotys">
                <i className="tf-globe" />
                <h3>Jėgų išbandymui</h3>
                <p>
                  Uždaviniai ir užduotys norintiems išbandyti savo Python
                  sugebėjimus
                </p>
              </a>
            </Slide>
          </Fade>
          <Fade className="block col-sm-6 col-md-4" triggerOnce delay={1200}>
            <Slide triggerOnce delay={1200}>
              <a href="https://wiki.pitonas.lt/resursai">
                <i className="tf-globe" />
                <h3>Angliški resursai</h3>
                <p>
                  Nuorodos į nemokamas knygas ir puslapius anglų kalba
                  (pažengusiems programuotojams)
                </p>
              </a>
            </Slide>
          </Fade>
        </div>
      </div>
    </section>
  );
}
