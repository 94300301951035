const DEFAULT = `import turtle

t = turtle.Turtle()

for c in ["red", "green", "grey", "blue"]:
  t.color(c)
  t.forward(75)
  t.left(90)
`;

const MOKYKLA = `print("Pirmoji klase")
print("1 + 1 =", 1 + 1)
print("2 + 4 =", 2 + 4)
print("5 - 2 =", 5 - 2)
print()
print("Trecioji klase")
print("243 - 23 =", 243 - 23)
print("12 * 4 =", 12 * 4)
print("12 / 3 =", 12 / 3)
print("13 / 3 =", 13 // 3, "liekana", 13 % 3)
print()
print("Penktoji klase")
print("123.56 - 62.12 =", 123.56 - 62.12)
print("(4 + 3) * 2 =", (4 + 3) * 2)
print("4 + 3 * 2 =", 4 + 3 * 2)
print("3 ** 2 =", 3 ** 2)`;

/**
 * return python code under the provided key
 */
export const codeSamples: Record<string, string> = {
  default: DEFAULT,
  mokykla: MOKYKLA,
};
