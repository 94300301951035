import { Fade, Slide, Zoom } from "react-awesome-reveal";
import { Player } from "@lottiefiles/react-lottie-player";
import "./Welcome.scss";

export default function Welcome() {
  return (
    <section className="hero-area bg-1">
      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <Zoom className="block" triggerOnce>
              <Player
                autoplay
                loop
                speed={1}
                src="./lottie.json"
                style={{ maxHeight: 500, maxWidth: 500 }}
                background="transparent"
              ></Player>
            </Zoom>
          </div>
          <div className="col-md-7">
            <div className="block">
              <Fade duration={1000}>
                <Slide direction="down" duration={500} triggerOnce>
                  <h1>
                    Viskas, ko reikia <br /> mokytis programuoti
                    <mark>Python</mark>
                  </h1>
                </Slide>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
