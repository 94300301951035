import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import './Navbar.scss';

const Navbar = () => {
  const [navClass, setNavClass] = useState(false);
  const handleScroll = () => {
    setNavClass(window.scrollY > 200);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      className={`navbar navbar-expand-lg fixed-top navigation ${
        navClass ? "animated" : "notanimated"
      }`}
    >
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <img src="logo.png" alt="pitonas" className="logo" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <FontAwesomeIcon icon={faBars} color='white' />
        </button>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav mb-2 mb-lg-0 menu">
            <li className="nav-item">
              <a className="nav-link" href="#about">
                PRADŽIA
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link external"
                target="_blank"
                rel="noreferrer"
                href="https://wiki.pitonas.lt/vadovelis"
              >
                VADOVĖLIS
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link external"
                target="_blank"
                rel="noreferrer"
                href="https://wiki.pitonas.lt/pamokos"
              >
                PAMOKOS
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link external"
                target="_blank"
                rel="noreferrer"
                href="./ide"
              >
                ONLINE PYTHON
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link external"
                target="_blank"
                rel="noreferrer"
                href="https://wiki.pitonas.lt/resursai"
              >
                KITI RESURSAI
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#contact">
                KONTAKTAI
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
